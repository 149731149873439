jQuery(function ($) {
  $(document).ready(function () {
    // Menu responsive
    $(".menu-resp").on("click", function (e) {
      e.preventDefault();
      $(".main-navigation").addClass("open");
      $(this).hide();
      $(".menu-resp.close").show();
    });
    $(".menu-resp.close").on("click", function (e) {
      e.preventDefault();
      $(".main-navigation").removeClass("open");
      $(this).hide();
      $(".menu-resp.open").show();
    });

    // Slideshow
    if ($(".home").length) {
      $(".slideshow").slick({
        autoplay: true,
        autoplayspeed: 4000,
        dots: true,
        arrows: false
      });
    }

    // Filtrer la map
    if ($(".page-id-310").length) {
      handleFilterMap($);
    }

    // Bouton CTA
    $('.cta-close').on('click', function () {
      $(this).parent().hide();
    })

    // Accordéon horaires fêtes ensemble paroissiaux
    $('.horaires-fetes .ep .horaires').hide();
    $('.horaires-fetes .ep .nom').on('click', function () {
      var horaire = $(this).siblings('.horaires')
      // $(this).parent().toggleClass('active');
      if (horaire.is(":hidden")) {
        horaire.show()
      } else {
        horaire.hide()
      }

    })

  });
});

function handleFilterMap($) {
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  };
  var filter = getUrlParameter("wpgmza_cat_checkbox");
  if (filter == null) return;

  setTimeout(function () {
    $("#wpgmza_filter_select").val(filter);
    $("#wpgmza_filter_select").trigger("change");
  }, 200);
}
// })(jQuery);
